// TODO: autoResetPageIndex doesn't work because we keep changing filters

import React, { useState, useEffect } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Button, Tabs, Tab } from 'react-bootstrap';

import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query'; //note: this is TanStack React Query V5
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import './App.css';

function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "…" : str;
};

function getFilters(tfFilters) {
  console.log(tfFilters);
  var result = JSON.parse(tfFilters);
  result.forEach(item => {
    if (Array.isArray(item.value)) {
    item.value = item.value.map(val => {
      return (typeof val === 'string' && !isNaN(Date.parse(val))) ? new dayjs(val) : val;
    });
  }
  });
  console.log(result)
  return result;
}

function TestFailures() {
  const location = useLocation();
  const navigate = useNavigate();
  const initialFilters = queryString.parse(location.search);
  const referer = document.referrer.match(/https:\/\/github.com\/MaterializeInc\/database-issues\/issues\/([0-9]+)/) ? document.referrer : "";

  const defaultFilters = '[{"id":"branch","value":"main"},{"id":"build_date","value":[null,null]}]';
  const defaultGlobalFilter = '';
  const defaultSorting = '[{"id": "build_date", "desc": true}]';
  const defaultPageIndex = 0;
  const defaultPageSize = 25;
  const [columnFilters, setColumnFilters] = useState(getFilters(initialFilters.tfFilters || (referer === "" ? defaultFilters : `[{"id": "issue", "value": "database-issues/${referer.replace(/.*\//, '')}"},{"id":"build_date","value":[null,null]}]`)));
  const [globalFilter, setGlobalFilter] = useState(initialFilters.tfGlobalFilter || defaultGlobalFilter);
  const [sorting, setSorting] = useState(JSON.parse(initialFilters.tfSorting || defaultSorting));
  const [pagination, setPagination] = useState({
    pageIndex: Number(initialFilters.tfPageIndex) || defaultPageIndex,
    pageSize: Number(initialFilters.tfPageSize) || defaultPageSize,
  });

  // Synchronize state with URL query parameters
  useEffect(() => {
    const qs = queryString.parse(location.search);
    const params = {key: "test-failures"};

    if ("issue" in qs) {
      setColumnFilters([{"id": "issue", "value": qs.issue}]);
      setGlobalFilter(defaultGlobalFilter);
      setSorting(JSON.parse(defaultSorting));
      setPagination({pageIndex: defaultPageIndex, pageSize: defaultPageSize});
      params["key"] = "test-failures";
    }

    if (JSON.stringify(columnFilters) !== JSON.stringify(JSON.parse(defaultFilters))) {
      params["tfFilters"] = JSON.stringify(columnFilters);
    }
    if (globalFilter !== defaultGlobalFilter) {
      params["tfGlobalFilter"] = globalFilter;
    }

    if (JSON.stringify(sorting) !== JSON.stringify(JSON.parse(defaultSorting))) {
      params["tfSorting"] = JSON.stringify(sorting);
    }
    if (pagination.pageIndex !== defaultPageIndex) {
      params["tfPageIndex"] = pagination.pageIndex;
    }
    if (pagination.pageSize !== defaultPageSize) {
      params["tfPageSize"] = pagination.pageSize;
    }

    if ((qs.key !== "issues" && qs.key !== "config") || "issue" in qs) {
      navigate({ search: queryString.stringify(params) }, { replace: true });
    }
  }, [columnFilters, globalFilter, sorting, pagination, navigate, location.search]);

  const { data: { data = [], meta } = {}, isError, isRefetching, isLoading, refetch } = useQuery({
    queryKey: ['failures-data', columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting],
    queryFn: async () => {
      const fetchURL = new URL("/ci-failures/errors", "https://static.30.128.156.178.clients.your-server.de");
      fetchURL.searchParams.set('start', `${pagination.pageIndex * pagination.pageSize}`);
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));
      fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
      const response = await fetch(fetchURL.href);
      const json = await response.json();
      return json;
    },
    placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
  });

  const columns = [
    {
      header: "Build Date",
      accessorKey: "build_date",
      columnDefType: "data",
      grow: false,
      size: 340,
      minSize: 340,
      Cell: ({ cell }) => new Date(Date.parse(cell.getValue())).toLocaleString(),
      filterVariant: "datetime-range",
    },
    {
      header: "Branch",
      accessorKey: "branch",
      columnDefType: "data",
      grow: false,
      size: 180,
      minSize: 120,
      Cell: ({ renderedCellValue, row }) => (
        <a target="_blank" rel="noreferrer" href={`https://buildkite.com/materialize/${encodeURI(row.original.build_identifier.split("#")[0])}/builds?branch=${encodeURIComponent(row.original.branch)}`}>{renderedCellValue}</a>
      ),
      filterFn: "contains",
    },
    {
      header: "Build",
      accessorKey: "build_identifier",
      columnDefType: "data",
      grow: false,
      size: 120,
      minSize: 120,
      Cell: ({ renderedCellValue, row }) => (
        <a target="_blank" rel="noreferrer" href={`https://buildkite.com/materialize/${encodeURI(row.original.build_identifier.split("#")[0])}/builds/${encodeURI(row.original.build_identifier.split("#")[1])}`}>{renderedCellValue}</a>
      ),
      filterFn: "contains",
    },
    {
      header: "Test",
      accessorKey: "test_suite",
      columnDefType: "data",
      grow: false,
      size: 200,
      minSize: 120,
      Cell: ({ renderedCellValue, row }) => (
        <a target="_blank" rel="noreferrer" href={`https://buildkite.com/materialize/${encodeURI(row.original.build_identifier.split("#")[0])}/builds/${encodeURI(row.original.build_identifier.split("#")[1])}#${encodeURIComponent(row.original.build_job_id)}`}>{renderedCellValue} (#{parseInt(row.original.test_retry_count) + 1})</a>
      ),
      filterFn: "contains",
    },
    {
      header: "Issue",
      accessorKey: "issue",
      columnDefType: "data",
      grow: false,
      size: 190,
      minSize: 120,
      Cell: ({ renderedCellValue, row }) => (
        row.original.issue ? (row.original.issue === "GITHUB INVALID REGEXP" ? <span>{renderedCellValue}</span> : row.original.issue === "UNKNOWN ERROR" ? <Button target="_blank" rel="noreferrer" style={{fontSize: "90%", "--bs-btn-padding-y": 0}} href={`https://github.com/MaterializeInc/database-issues/issues/new?assignees=&labels=C-bug&projects=&template=01-bug.yml&title=${encodeURIComponent(row.original.test_suite + ": " + truncate(row.original.content.replace(/Unknown error in .*?:/, "").replace(/.*? kernel:/, "").trim(), 90))}&version=${encodeURIComponent(row.original.mz_version + " (" + row.original.branch + ", " + row.original.commit_hash + ")")}&issue=${encodeURIComponent(row.original.test_suite + " failed on " + row.original.branch + " branch at " + row.original.build_date + ": \n```\n" + row.original.content.trim() + "\n```\n[Buildkite run with full logs](https://buildkite.com/materialize/" + row.original.build_identifier.split("#")[0] + "/builds/" + row.original.build_identifier.split("#")[1] + "#" + row.original.build_job_id + ")\n```\nci-regexp: TODO: Add a unique string/regex to match the issue or remove if not suitable\nci-apply-to: " + row.original.test_suite + "\n```\nUse [ci-failures](https://ci-failures.dev.materialize.com/) to check further occurrences of this issue")}`}>{renderedCellValue}</Button> : <a target="_blank" rel="noreferrer" href={`https://github.com/MaterializeInc/${row.original.issue.replace("/", "/issues/").replace(" (POTENTIAL REGRESSION)", "").replace(" (KNOWN ISSUE)", "")}`}>{renderedCellValue}</a>) : ""
      ),
      filterFn: "contains",
    },
    {
      header: "Text",
      accessorKey: "content",
      columnDefType: "data",
      grow: true,
      size: 200,
      minSize: 120,
      Cell: ({ renderedCellValue, row }) => (
        <span title={row.original.content}>{renderedCellValue}</span>
      ),
      filterFn: "contains",
    },
    {
      header: "Version",
      accessorKey: "mz_version",
      columnDefType: "data",
      grow: false,
      size: 127,
      minSize: 120,
      filterFn: "contains",
    },
    {
      header: "Hash",
      accessorKey: "commit_hash",
      columnDefType: "data",
      grow: false,
      size: 127,
      minSize: 120,
      Cell: ({ renderedCellValue, row }) => (
        <a target="_blank" rel="noreferrer" href={`https://github.com/MaterializeInc/materialize/commit/${encodeURI(row.original.commit_hash)}`}>{renderedCellValue}</a>
      ),
      filterFn: "contains",
    },
    {
      header: "Step",
      accessorKey: "build_step_key",
      columnDefType: "data",
      size: 250,
      minSize: 120,
      filterFn: "contains",
    },
    {
      header: "Retries",
      accessorKey: "test_retry_count",
      columnDefType: "data",
      size: 120,
      minSize: 120,
      filterFn: "contains",
    },
    {
      header: "Occurrences",
      accessorKey: "occurrence_count",
      columnDefType: "data",
      size: 160,
      minSize: 120,
      filterFn: "contains",
    },
    {
      header: "Build ID",
      accessorKey: "build_id",
      columnDefType: "data",
      size: 280,
      minSize: 120,
      filterFn: "contains",
    },
    {
      header: "Build Job ID",
      accessorKey: "build_job_id",
      columnDefType: "data",
      size: 280,
      minSize: 120,
      filterFn: "contains",
    }
  ]

  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode: "grid",
    enableColumnResizing: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    muiTableContainerProps: { sx: { maxHeight: 'calc(100vh - 10em)' } },
    renderToolbarInternalActions: ({ table }) => (
      <a href="https://github.com/MaterializeInc/ci-failures">Source code</a>
    ),
    enableDensityToggle: false,
    enableFilterMatchHighlighting: true,
    enableFullScreenToggle: false,
    initialState: { showColumnFilters: true, columnVisibility: {"build_step_key": false, "test_retry_count": false, "occurrence_count": false, "build_id": false, "build_job_id": false}, density: "compact", showGlobalFilter: true, },
    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
    manualSorting: true, //turn off built-in client-side sorting
    //give loading spinner somewhere to go while loading
    muiTableBodyProps: {
      children: isLoading ? (
        <tr style={{ height: '200px' }}>
          <td />
        </tr>
      ) : undefined,
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: () => (
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),
    rowCount: meta?.totalRowCount ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return <MaterialReactTable table={table} />;
}

function Issues() {
  const location = useLocation();
  const navigate = useNavigate();

  const initialFilters = queryString.parse(location.search);

  const defaultFilters = '[{"id":"first_occurrence","value":[null,null]},{"id":"last_occurrence","value":[null,null]}]';
  const defaultGlobalFilter = '';
  const defaultSorting = '[{"id": "last_occurrence", "desc": true}]';
  const defaultPageIndex = 0;
  const defaultPageSize = 25;
  const [columnFilters, setColumnFilters] = useState(getFilters(initialFilters.isFilters || defaultFilters));
  const [globalFilter, setGlobalFilter] = useState(initialFilters.isGlobalFilter || defaultGlobalFilter);
  const [sorting, setSorting] = useState(JSON.parse(initialFilters.isSorting || defaultSorting));
  const [pagination, setPagination] = useState({
    pageIndex: Number(initialFilters.isPageIndex) || defaultPageIndex,
    pageSize: Number(initialFilters.isPageSize) || defaultPageSize,
  });

  // Synchronize state with URL query parameters
  useEffect(() => {
    const params = {key: "issues"}
    if (JSON.stringify(columnFilters) !== JSON.stringify(JSON.parse(defaultFilters))) {
      params["isFilters"] = JSON.stringify(columnFilters);
    }
    if (globalFilter !== defaultGlobalFilter) {
      params["isGlobalFilter"] = globalFilter;
    }

    if (JSON.stringify(sorting) !== JSON.stringify(JSON.parse(defaultSorting))) {
      params["isSorting"] = JSON.stringify(sorting);
    }
    if (pagination.pageIndex !== defaultPageIndex) {
      params["isPageIndex"] = pagination.pageIndex;
    }
    if (pagination.pageSize !== defaultPageSize) {
      params["isPageSize"] = pagination.pageSize;
    }

    if (queryString.parse(location.search).key === "issues") {
      navigate({ search: queryString.stringify(params) }, { replace: true });
    }
  }, [columnFilters, globalFilter, sorting, pagination, navigate, location.search]);

  // Consider storing this code in a custom hook (i.e useFetchUsers)
  const { data: { data = [], meta } = {}, isError, isRefetching, isLoading, refetch } = useQuery({
    queryKey: ['issues-data', columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting],
    queryFn: async () => {
      const fetchURL = new URL("/ci-failures/issues", "https://static.30.128.156.178.clients.your-server.de");
      fetchURL.searchParams.set('start', `${pagination.pageIndex * pagination.pageSize}`);
      fetchURL.searchParams.set('size', `${pagination.pageSize}`);
      fetchURL.searchParams.set('sorting', JSON.stringify(sorting ?? []));
      fetchURL.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
      fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
      const response = await fetch(fetchURL.href);
      const json = await response.json();
      return json;
    },
    placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
  });

  const columns = [
    {
      header: "Issue",
      accessorKey: "issue",
      columnDefType: "data",
      Cell: ({ renderedCellValue, row }) => (
        <a target="_blank" rel="noreferrer" href={`https://github.com/MaterializeInc/${row.original.issue.replace("/", "/issues/")}`}>{renderedCellValue}</a>
      ),
      grow: false,
      size: 160,
      minSize: 120,
      filterFn: "contains",
    },
    {
      header: "State",
      accessorKey: "state",
      columnDefType: "data",
      grow: false,
      size: 120,
      minSize: 120,
      filterVariant: "select",
      filterSelectOptions: ["open", "closed"],
    },
    {
      header: "Occurrences",
      accessorKey: "occurrences",
      columnDefType: "data",
      Cell: (props) => (
        <a href="#" onClick={(e) => {e.preventDefault(); navigate(`?issue=${encodeURIComponent(props.row.original.issue)}`)}}>{props.row.original.occurrences}</a>
      ),
      grow: false,
      size: 120,
      minSize: 120,
      muiTableBodyCellProps: { align: "right" },
      filterVariant: 'range-slider',
      filterFn: 'betweenInclusive',
      muiFilterSliderProps: {
        marks: true,
        max: 150,
        min: 0,
        step: 10,
      },
    },
    {
      header: "First Occurrence",
      accessorKey: "first_occurrence",
      columnDefType: "data",
      Cell: ({ cell }) => new Date(Date.parse(cell.getValue())).toLocaleString(),
      grow: false,
      size: 340,
      minSize: 340,
      filterVariant: "datetime-range",
    },
    {
      header: "Last Occurrence",
      accessorKey: "last_occurrence",
      columnDefType: "data",
      Cell: ({ cell }) => new Date(Date.parse(cell.getValue())).toLocaleString(),
      grow: false,
      size: 340,
      minSize: 340,
      filterVariant: "datetime-range",
    },
    {
      header: "Title",
      accessorKey: "title",
      columnDefType: "data",
      size: 300,
      minSize: 120,
      Cell: ({ renderedCellValue, row }) => (
        <span title={row.original.title}>{renderedCellValue}</span>
      ),
      filterFn: "contains",
    },
    {
      header: "ci-regexp",
      accessorKey: "ci_regexp",
      columnDefType: "data",
      size: 120,
      minSize: 120,
      Cell: ({ renderedCellValue, row }) => (
          <span title={row.original.ci_regexp}>{renderedCellValue}</span>
      ),
      filterFn: "contains",
    }
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    layoutMode: "grid",
    enableColumnResizing: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    muiTableContainerProps: { sx: { maxHeight: 'calc(100vh - 10em)' } },
    enableDensityToggle: false,
    enableFilterMatchHighlighting: true,
    enableFullScreenToggle: false,
    initialState: { showColumnFilters: true, density: "compact", showGlobalFilter: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiTableBodyProps: { children: isLoading ? (<tr style={{ height: '200px' }}><td /></tr>) : undefined },
    muiToolbarAlertBannerProps: isError ? { color: 'error', children: 'Error loading data' } : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: () => (
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),
    rowCount: meta?.totalRowCount ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return <MaterialReactTable table={table} />;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
    },
  },
});

const TabsComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { key } = queryString.parse(location.search);
  const [activeKey, setActiveKey] = useState(key || 'test-failures');

  useEffect(() => {
    if (key && key !== activeKey) {
      setActiveKey(key);
    }
  }, [key, activeKey]);

  const handleKeyChange = (newKey) => {
    setActiveKey(newKey);
    navigate(newKey !== "test-failures" ? `?key=${newKey}` : "");
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Tabs id="key" activeKey={activeKey} onSelect={handleKeyChange}>
        <Tab eventKey="test-failures" title="Test Failures">
          <TestFailures />
        </Tab>
        <Tab eventKey="issues" title="Issues">
          <Issues />
        </Tab>
      </Tabs>
    </QueryClientProvider>
  );

};

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <Routes>
          <Route path="/" element={<TabsComponent />} />
        </Routes>
      </Router>
    </LocalizationProvider>
  );
}
